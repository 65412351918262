import React from "react";
import './Landing2.css'

const Landing = () => {

    return (
      <div className="intro">
        <h1>WELCOME!  MY NAME IS <span className="my-name">NATALIE KINSAUL</span> AND I AM A FULL-STACK WEB DEVELOPER. </h1>
      </div>
    )

}

export default Landing;